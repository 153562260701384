import { Inject, Injectable, forwardRef } from "@angular/core";

import { AngularFireDatabase } from "@angular/fire/compat/database";
import { Role } from "../../constants/enums"; 
import lodash from "lodash";
import { of } from "rxjs";
import { CommonServiceService } from "../common-service.service";
import { CacheService, CacheKeys } from "../cache/cache.service";
import { ApiHelperService, CloudFnNames } from "../apiHelperService/api-helper.service";
//futere planning
//  comming soon
// import {
//   ApiHelperProvider,
//   CloudFnNames,
// } from "../../providers/api-helper/api-helper";


@Injectable({
  providedIn: 'root'
})
export class InheritanceService {
  constructor(
    private db: AngularFireDatabase,
    @Inject(forwardRef(() => CommonServiceService)) public commonFun,
    private cacheProvider: CacheService,
    private apiHelperProvider: ApiHelperService
  ) {}

  // --- helper method to generate DB path by replacing tags
  public prepareDBPath(path: string, replacementData: any) {
    let evaluatedPath: string = ReplacementTags.replace(path, replacementData);
    return evaluatedPath.replace(/\/\//g, "/");
  }

  // --- helper method to fetch angular firebase instance for fetching data (list or object)
  private getAngularFireInstance(path, type: "list" | "object") {
    return type == "list" ? this.db.list(path) : this.db.object(path);
  }

  // --- remove cache of inherited data
  removeCache(path, userDBNode, userID) {
    // --- prepare DB Path
    let replacementData = ReplacementTags.prepareReplacementData(
      userDBNode,
      userID
    );
    let dbPath = this.prepareDBPath(path, replacementData);

    this.cacheProvider.remove(CacheKeys.InheritanceData, dbPath);
  }

  /**
   * read value from DB
   * @param userID user id
   * @param path path of the DB
   * @param type type of the data to be readed
   * @param useCache if cached data should be used or no
   * @returns promise of any type data readed from database
   */
  async readFromDB(
    userID: string,
    path: string,
    type: "list" | "object",
    userDBNode?: string,
    useCache: boolean = false,
    queryInterceptor?: QueryInterceptor
  ): Promise<any> {
    // --- prepare DB Path
    let replacementData = ReplacementTags.prepareReplacementData(
      userDBNode,
      userID
    );
    let dbPath = this.prepareDBPath(path, replacementData);

    // --- read data from cache
    if (useCache) {
      let cachedData = this.cacheProvider.get(
        CacheKeys.InheritanceData,
        `${dbPath}${
          queryInterceptor ? `/${queryInterceptor.uniqueIdentifier}` : ""
        }`
      );
      if (cachedData) return cachedData.value;
    }

    // --- read data from DB
    let query = this.getAngularFireInstance(dbPath, type).query;
    if (queryInterceptor) {
      query = queryInterceptor.execute(query);
    }
    let snapshot = await query.once("value");
    let data = snapshot.val();

    // --- set cache
    this.cacheProvider.set(
      CacheKeys.InheritanceData,
      `${dbPath}${
        queryInterceptor ? `/${queryInterceptor.uniqueIdentifier}` : ""
      }`,
      data
    );

    return data;
  }

  /**
   * read org data at given path
   * @param orgID org id
   * @param path path of the DB
   * @param type type of the data to be readed
   * @param useCache if cached data should be used or no
   * @returns promise of InheritanceData array containing data of organization for the given path
   */
  async getOrgData(
    orgID: string,
    path: string,
    type: "list" | "object",
    orgDBNode?: string,
    useCache: boolean = false,
    queryInterceptor?: QueryInterceptor
  ): Promise<InheritanceData[]> {
    // --- get data from firebase DB
    let orgData = await this.readFromDB(
      orgID,
      path,
      type,
      orgDBNode,
      useCache,
      queryInterceptor
    ).then((data) => {
      return {
        ownerID: orgID,
        data: data,
      };
    });

    return [orgData];
  }

  /**
   * read studio data at given path by inheritance
   * @param studioID studio ID
   * @param path path of the DB
   * @param type type of the data to be readed
   * @param studioUsersData studio users data
   * @param useCache if cached data should be used or no
   * @returns promise of InheritanceData array containing data of studios (in ascending order - eg. studio OR conference > region > league) for the given path
   */
  async getByStudioInheritance(
    studioID: string,
    path: string,
    type: "list" | "object",
    studioUsersData?: { [studioID: string]: any },
    studioDBNode?: string,
    useCache: boolean = false,
    queryInterceptor?: QueryInterceptor
  ): Promise<InheritanceData[]> {
    let studioData = lodash.get(studioUsersData, studioID);
    if (!studioData) {
      studioData = await this.commonFun.getStudio(studioID, useCache);
    }

    let resultData: [InheritanceData];
    let fetchDataFromDBPromises = [];

    // --- read league, region IDs
    let leagueID, regionID;
    leagueID = lodash.get(studioData, "leagueID");
    regionID = lodash.get(studioData, "regionID");

    // --- read league, region data
    let leagueData, regionData;
    let readLeagueRegionDataPromises = [];
    readLeagueRegionDataPromises.push(
      lodash.get(studioUsersData, leagueID)
        ? of(lodash.get(studioUsersData, leagueID)).toPromise()
        : this.commonFun.getStudio(leagueID, useCache)
    );
    readLeagueRegionDataPromises.push(
      lodash.get(studioUsersData, regionID)
        ? of(lodash.get(studioUsersData, regionID)).toPromise()
        : this.commonFun.getStudio(regionID, useCache)
    );
    [leagueData, regionData] = await Promise.all(readLeagueRegionDataPromises);

    // supermin > teammate OR supermin > league > region > conference
    const inheritanceRecursion = (_studioData) => {
      if (!_studioData || !_studioData.key) return;

      // --- add promise to fetch data from DB
      fetchDataFromDBPromises.push(
        this.readFromDB(
          _studioData.key,
          path,
          type,
          studioDBNode,
          useCache,
          queryInterceptor
        ).then((data) => {
          return {
            ownerID: _studioData.key,
            data: data,
          };
        })
      );

      if (regionID && _studioData.inheritFrom == regionID) {
        inheritanceRecursion(regionData);
      } else if (leagueID && _studioData.inheritFrom == leagueID) {
        inheritanceRecursion(leagueData);
      }
    };

    // start inheritance recursion
    inheritanceRecursion(studioData);

    // --- resolve fetchDataFromDBPromises
    let fetchDataFromDBPromisesRes: any = await Promise.all(
      fetchDataFromDBPromises
    );
    resultData = fetchDataFromDBPromisesRes;

    // --- return results (its an array containing data from left to right)
    return resultData;
  }

  /**
   * read superadmin data at given path
   * @param userID superadmin id
   * @param path path of the DB
   * @param type type of the data to be readed
   * @param useCache if cached data should be used or no
   * @returns promise of InheritanceData array containing data of superadmin for the given path
   */
  private async getSuperAdminData(
    userID: string,
    path: string,
    type: "list" | "object",
    superadminDBNode?: string,
    useCache: boolean = false,
    queryInterceptor?: QueryInterceptor
  ): Promise<InheritanceData[]> {
    // --- get data from firebase DB
    let superadminData = await this.readFromDB(
      userID,
      path,
      type,
      superadminDBNode,
      useCache,
      queryInterceptor
    ).then((data) => {
      return {
        ownerID: "superadmin",
        data: data,
      };
    });

    return [superadminData];
  }

  /**
   * read data from any path of the DB by inheritance
   * @param role role of the user
   * @param userID user id (org/studio ID)
   * @param path path of the DB
   * @param type type of the data to be readed
   * @param usersData org & studio users data (Map of the type ID:data)
   * @param superadminUserID superadmin's user id used in DB (eg. superadmin or null)
   * @param useCache if cached data should be used or no
   * @returns promise of InheritanceData array containing data of org, studios and superadmin (in ascending order - eg. org > (studio OR conference > region > league) > superadmin) for the given path
   */
  async getByInheritance(
    role: string,
    userID: string,
    path: string,
    type: "list" | "object",
    usersData?: { [userID: string]: any },
    userDBNodes?: { [role: string]: string | null },
    superadminUserID: string | null = "superadmin",
    useCache: boolean = false,
    queryInterceptor?: QueryInterceptor
  ): Promise<InheritanceData[]> {
    // --- params validation
    if (!role || (role != Role.SUPERADMIN && !userID) || !path || !type)
      throw Error("Params missing in getByInheritance method call");

    let resultData: InheritanceData[];

    if (role == Role.SUPERADMIN) {
      // --- get data from firebase DB
      resultData = await this.getSuperAdminData(
        superadminUserID,
        path,
        type,
        lodash.get(userDBNodes, Role.SUPERADMIN),
        useCache,
        queryInterceptor
      );
    } else if (role == Role.STUDIO) {
      let fetchDataFromDBPromises = [];

      // --- add promise to read studio data
      fetchDataFromDBPromises.push(
        this.getByStudioInheritance(
          userID,
          path,
          type,
          usersData,
          lodash.get(userDBNodes, Role.STUDIO),
          useCache,
          queryInterceptor
        )
      );

      // --- add promise to read superadmin data
      fetchDataFromDBPromises.push(
        this.getSuperAdminData(
          superadminUserID,
          path,
          type,
          lodash.get(userDBNodes, Role.SUPERADMIN),
          useCache,
          queryInterceptor
        )
      );

      // --- resolve fetchDataFromDBPromises
      let fetchDataFromDBPromisesRes: any = await Promise.all(
        fetchDataFromDBPromises
      );

      // --- set resultData
      let studiosData = fetchDataFromDBPromisesRes[0];
      let superadminData = fetchDataFromDBPromisesRes[1];
      resultData = [...studiosData, ...superadminData];
    } else if (role == Role.ORG) {
      let fetchDataFromDBPromises = [];

      // --- add promise to read org data
      fetchDataFromDBPromises.push(
        this.getOrgData(
          userID,
          path,
          type,
          lodash.get(userDBNodes, Role.ORG),
          useCache,
          queryInterceptor
        )
      );

      // --- read organization data
      let orgData = lodash.get(usersData, userID);
      if (!orgData) {
        let reqBody = { orgID: userID, propsToReturn: ["studioID"] };
        let orgDataRes = await this.apiHelperProvider.postToCloudFn(
          CloudFnNames.getOrgData,
          reqBody
        );
        orgData = lodash.get(orgDataRes, "result.data");
      }

      // --- add promise to read studio data by inheritance
      let studioID = lodash.get(orgData, "studioID");
      if (studioID)
        fetchDataFromDBPromises.push(
          this.getByStudioInheritance(
            studioID,
            path,
            type,
            usersData,
            lodash.get(userDBNodes, Role.STUDIO),
            useCache,
            queryInterceptor
          )
        );

      // --- add promise to read superadmin data
      fetchDataFromDBPromises.push(
        this.getSuperAdminData(
          superadminUserID,
          path,
          type,
          lodash.get(userDBNodes, Role.SUPERADMIN),
          useCache,
          queryInterceptor
        )
      );

      // --- resolve fetchDataFromDBPromises
      let fetchDataFromDBPromisesRes: any = await Promise.all(
        fetchDataFromDBPromises
      );

      // --- set resultData
      let orgsData = fetchDataFromDBPromisesRes[0];
      let studiosData = fetchDataFromDBPromisesRes[1];
      let superadminData = fetchDataFromDBPromisesRes[2];
      resultData = [...orgsData, ...studiosData, ...superadminData];
    }

    // --- return results
    return resultData;
  }
}

export enum ReplacementTags {
  USER_DB_NODE = "%userDBNode%",
  USER_ID = "%userID%",
  CYCLE = "%cycle%",
}

export namespace ReplacementTags {
  export function getAll() {
    // --- remove functions from object & return values array only
    return lodash.filter(ReplacementTags, (value, key) =>
      lodash.isString(value)
    );
  }

  export function prepareReplacementData(
    userDBNode: string,
    userID: string,
    cycle?: string
  ) {
    return {
      "%userDBNode%": userDBNode,
      "%userID%": userID || null,
      "%cycle%": cycle,
    };
  }

  export function replace(source: string, replacementData: any) {
    let replacementTags = ReplacementTags.getAll();
    lodash.each(replacementTags, (tag: any) => {
      if (!source.includes(tag)) return;

      let replacementValue = lodash.get(replacementData, tag);
      source = source.replace(tag, replacementValue || "");
    });
    return source;
  }
}

export class InheritanceData {
  ownerID: string;
  data: string;
}
import firebase from 'firebase/compat/app';

export type QueryInterceptor = {
  uniqueIdentifier: string;
  execute: (dbReference: firebase.database.Query) => firebase.database.Query;
};

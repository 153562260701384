import { Injectable, Inject, forwardRef } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";


import lodash from "lodash";
import { timeout } from "rxjs/operators";
import { environment } from "../../../../environments/environment.stage";
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url: string = `${environment.apiBaseUrl}`
  static zeroUrl: string = "https://high5.id/zero/";
  static zeroUrlLocal: string =
    "http://localhost:81/jaleatech/vip.pass/zero.hardware/";

  public debug: boolean = true;
  public log: string[];
  public t0: number = 0;
  public t1: number = 0;
  public platforms: string[];

  constructor(
    public http: HttpClient,
  ) {}

  isPlatform(platformName: string) {
    return lodash.includes(this.platforms, platformName);
  }

  // performanceStart() {
  //   if (this.debug === false) {
  //     return false;
  //   }

  //   this.t0 = performance.now();
  // }

  performanceEnd(nameOfMethod: string) {
    if (this.debug === false) {
      return false;
    }

    this.t1 = performance.now();

    let duration = (this.t1 - this.t0).toFixed(4);

    if (!this.log) this.log = [];
    this.log.push(nameOfMethod + ": " + duration + " ms");

    return duration;
  }

  // performancePrintLog(toConsole: boolean, download: boolean) {
  //   if (this.debug === false) {
  //     return false;
  //   }

  //   let text = this.log.join("\n");
  //   if (toConsole === true) {
  //     this.log = undefined;
  //   }

  //   if (download === true) {
  //     var element = document.createElement("a");
  //     element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
  //     element.setAttribute("download", "log_" + Date.now() + ".txt");
  //     element.style.display = "none";
  //     document.body.appendChild(element);
  //     element.click();
  //     document.body.removeChild(element);
  //   }
  // }

  get(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params.set(k, params[k]);
      }
    }
    return this.http.get(endpoint, reqOpts);
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    // --- assemble url
    let url = endpoint;
    if (endpoint.indexOf("http") === -1) {
      url = this.url + "/" + endpoint;
    }
    return this.http.post(url, body, reqOpts);
  }

  put(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.url + "/" + endpoint, body, reqOpts);
  }

  delete(endpoint: string, reqOpts?: any) {
    return this.http.delete(this.url + "/" + endpoint, reqOpts);
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.url + "/" + endpoint, body, reqOpts);
  }

  generateS3SignUrl(obj){
   let dataObj:any = {
      "extensions": obj.extensions,
      "path": obj.path,
    }
    if(obj.fileName){
      dataObj.fileName = obj.fileName
    }
    if(obj.bucket) {
      dataObj.bucket = obj.bucket
    }
    const headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('x-api-key', environment.awsImageUpload.xApiKey);
    return this.post(environment.awsImageUpload.signUrlApi, dataObj, { 'headers': headers })
  }
  uploadImageOnS3(signUrlApi, fileObj, index?){
    let obj = {file: fileObj};
    let file : File = fileObj;
    let head:any = {
      // NOTE: Because we are posting a Blob (File is a specialized Blob
      // object) as the POST body, we have to include the Content-Type
      // header. If we don't, the server will try to parse the body as
      // plain text.
      headers: {
        "Content-Type": file.type
      },
      // added responseType as text because response is getting null
      responseType: 'text'
    }
    if(lodash.isNil(index)) {
      return this.http.put(signUrlApi, file, head)
    } else {
      // --- if index present, that means image upload triggered from loop,
      // we have special formula to find timeout value of the request by the index.
      // formula -> "timeout = (index * 300) + 20000 milliseconds" is a best timeout can be expected for request to be completed
      return this.http.put(signUrlApi, file, head).pipe(timeout((index * 500) + 20000))
    }
   }

  imageUpload(obj, fileObj) {
    return new Promise((response, reject) => {
      this.generateS3SignUrl(obj).subscribe({
        next: (res) => {
          if (res) {
            this.uploadImageOnS3(res[0].signedUrl, fileObj).subscribe({
              next: () => {
                response(res[0].fileName)
              },
              error: (err) => {
                reject()
              }
            })
          }
        },
        error: (err) => {
          reject()
        }
      })
    })
  }
  
   deleteImageFolder(path){
    let url = `${environment.awsImageUpload.deleteFolderApi}${path}`
    const headers= new HttpHeaders()
    .set('x-api-key', environment.awsImageUpload.xApiKey);

    let head:any = {
      // NOTE: Because we are posting a Blob (File is a specialized Blob
      // object) as the POST body, we have to include the Content-Type
      // header. If we don't, the server will try to parse the body as
      // plain text.
      headers: {
        'x-api-key': environment.awsImageUpload.xApiKey
      },
      // added responseType as text because response is getting null
      responseType: 'text'
    }

    return this.http.delete(url, head)
   }

   dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  static updateShortenUrl(longUrl: string, shortUrl:string) {
    let keywordArr = shortUrl.split('/');
    let keyword = keywordArr[keywordArr.length-1]
    return new Promise(resolve => {
      $.ajax({
        url: `https://high5.id/p/yourls-update.php`,
        type: 'POST',
        data: {
          username: environment.yourlsObj.username,
          password: environment.yourlsObj.password,
          action: 'shorturl',
          format: 'json',
          url: longUrl,
          keyword: keyword
        },
        success: response => {
          console.log("response",response);
          resolve(response)
        },
        error: err => {
          console.log("err",err);
          resolve(err);
        }
      })
    });
  }
  
  static shortenUrl(longUrl: string) {
    return new Promise(resolve => {
      $.ajax({
        url: `https://high5.id/p/yourls-api.php`,
        type: 'POST',
        data: {
          username: environment.yourlsObj.username,
          password: environment.yourlsObj.password,
          action: 'shorturl',
          format: 'json',
          url: longUrl
        },
        success: response => {
          // console.log("response",response);
          resolve(response.shorturl)
        },
        error: err => {
          // console.log("err",err);
          resolve(err);
        }
      })
    });
  }

  multipleShortUrl(urlList: any[]) {
    return new Promise((resolve, reject) => {
      let body = {
        username: environment.yourlsObj.username,
        password: environment.yourlsObj.password,
        urls: urlList
      }
      this.http.post("https://high5.id/p/yourls-api-multiple-new.php", body).subscribe({
        next: (res) => {
          resolve(res);
        }, error: (err) => {
          resolve(err);
        }
      })
    })
  }
}
